<script lang="ts">
  import 'tw-elements';
  import { ethers, utils } from 'ethers';
  import { onDestroy, onMount, tick } from 'svelte';
  import { Accordion, AccordionItem } from 'svelte-collapsible';
  import * as CONTRACT_ABI from './abi.json';

  const CHAIN_ID = 137;
  const CONTRACT_ADDRESS = '0xe1EAedCcEA8C6949c89fcdd7aa802300528Dfd06';

  let provider: ethers.providers.Web3Provider = null,
    signer: ethers.Signer = null,
    contract: ethers.Contract = null,
    connectionError: string = null;

  let toBePurchasedCount = null,
    latestTicketPrice = ethers.BigNumber.from(0);

  let disableCrossmint = false;

  const purchaseTicketsForm = {
    numTickets: '',
    promoCode: '',
    loading: false,
    successMessage: '',
    errorMessage: '',
  };

  const chainChangedListener = (chainId: string) => {
    if (parseInt(chainId, 16) !== CHAIN_ID) {
      connectionError = 'Please connect to the Polygon Network.';
    } else {
      connectionError = null;
    }
  };

  const accountsChangedListener = (accounts) => {
    if (accounts.length === 0) {
      signer = null;
      connectionError =
        'You must have at least one account connected to this website.';
    } else {
      signer = provider.getSigner();
      connectionError = null;
    }
  };

  const connectWallet = async () => {
    connectionError = '';
    provider = signer = contract = null;

    if (!window.ethereum) {
      connectionError = 'MetaMask has not been installed in your browser.';
      return;
    }

    try {
      const _provider = new ethers.providers.Web3Provider(window.ethereum);
      await _provider.send('eth_requestAccounts', []);

      const _signer = _provider.getSigner();
      if (_signer === null) {
        connectionError =
          'You must have at least one account connected to this website.';
        return;
      }

      const { chainId } = await _provider.getNetwork();
      if (chainId !== CHAIN_ID) {
        connectionError = 'Please connect to the Polygon Network.';
        return;
      }

      const _contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        CONTRACT_ABI.abi,
        _provider
      );

      provider = _provider;
      signer = _signer;
      contract = _contract;

      window.ethereum.on('chainChanged', chainChangedListener);
      window.ethereum.on('accountsChanged', accountsChangedListener);
    } catch {
      connectionError =
        'You must connect MetaMask before purchasing tickets.';
    }
  };

  const init = async () => {
    if (provider === null || signer === null || contract === null)
      await connectWallet();

    if (provider === null || signer === null || contract === null)
      throw new Error('WALLET_CONN_FAILED');

    if (toBePurchasedCount === null || latestTicketPrice === null) {
      const totalTickets = await contract.TOTAL_TICKETS();
      const ticketCounter = await contract.ticketCounter();
      toBePurchasedCount = totalTickets.sub(ticketCounter).toString();

      latestTicketPrice = await contract.getTicketPriceInWei();
    }
  };

  const purchaseTickets = async () => {
    await init();
    if (purchaseTicketsForm.loading === true) return;

    purchaseTicketsForm.loading = true;
    purchaseTicketsForm.successMessage = '';
    purchaseTicketsForm.errorMessage = '';

    const numTickets = parseInt(purchaseTicketsForm.numTickets);
    const promoCode = purchaseTicketsForm.promoCode.trim();

    if (isNaN(numTickets) || numTickets <= 0) {
      purchaseTicketsForm.errorMessage =
        'Quantity of tickets must be greater than 0.';
      purchaseTicketsForm.loading = false;
      return;
    }

    const totalTickets = await contract.TOTAL_TICKETS();
    const ticketCounter = await contract.ticketCounter();

    if (ticketCounter.add(numTickets).gt(totalTickets)) {
      purchaseTicketsForm.errorMessage = 'Not enough tickets are available.';
      purchaseTicketsForm.loading = false;
      return;
    }

    if (
      promoCode !== '' &&
      (promoCode.length !== 66 || !/^0x[0-9a-fA-F]{64}$/.test(promoCode))
    ) {
      purchaseTicketsForm.errorMessage = 'Invalid promo code was provided.';
      purchaseTicketsForm.loading = false;
      return;
    }

    try {
      const tx = await contract
        .connect(signer)
        .purchaseTickets(
          numTickets,
          promoCode !== '' ? promoCode : ethers.constants.HashZero,
          await signer.getAddress(),
          {
            value: latestTicketPrice.mul(numTickets).add(1000),
          }
        );
      const receipt = await tx.wait();

      const purchaseFilter = contract.filters.TicketsPurchased(
        await signer.getAddress()
      );
      const purchaseEvents = await contract.queryFilter(
        purchaseFilter,
        receipt.blockHash
      );

      const peStartingId = purchaseEvents[0].args._startingId;
      const peAmount = purchaseEvents[0].args._amount;

      let successMessage = `Your ticket IDs:\n${peStartingId}`;
      for (let i = 1; i < peAmount; i++)
        successMessage = successMessage.concat(
          `, ${peStartingId.add(i).toString()}`
        );

      const promoCodeFilter = contract.filters.PromoCodeIssued(
        await signer.getAddress()
      );
      const promoCodeEvents = await contract.queryFilter(
        promoCodeFilter,
        receipt.blockHash
      );

      promoCodeEvents.forEach((event) => {
        const pcePromoCode = event.args._promoCode;
        successMessage = successMessage.concat(
          `\n\nPromo code issued: ${pcePromoCode}`
        );
      });

      purchaseTicketsForm.successMessage = successMessage;
    } catch (e) {
      console.log(e);
      purchaseTicketsForm.errorMessage =
        'Transaction failed. Please try again with the proper inputs.';
    }

    purchaseTicketsForm.loading = false;
  };

  const updateTicketPrice = async () => {
    await init();

    const promoCode = purchaseTicketsForm.promoCode.trim();
    if (promoCode === '') {
      latestTicketPrice = await contract.getTicketPriceInWei();
      return;
    }

    try {
      const owners = await contract.promoCodeOwners(promoCode);
      if (owners.distributor !== ethers.constants.AddressZero) {
        latestTicketPrice = latestTicketPrice.mul(9).div(10);
      }
    } catch {
      latestTicketPrice = await contract.getTicketPriceInWei();
    }
  };

  onMount(async () => {
    init();
  });

  onDestroy(async () => {
    window.ethereum.removeListener('chainChanged', chainChangedListener);
    window.ethereum.removeListener('accountsChanged', accountsChangedListener);
  });
</script>

<main
  class="bg-primary-green min-h-screen text-primary-text font-semibold tracking-wider"
  style="font-family: courier, courier new, serif;"
>
  <img src="images/logo.svg" class="h-96 mx-auto" alt="Logo" />

  <div class="max-w-screen-lg mx-auto">
    <div>
      {#if connectionError !== '' && connectionError !== null}
        <div class="text-red-500">{connectionError}</div>
      {/if}

      <form class="w-full" on:submit|preventDefault={purchaseTickets}>
        <input
          class="w-full rounded p-3 mb-1"
          type="text"
          placeholder="Quantity of tickets"
          bind:value={purchaseTicketsForm.numTickets}
          on:keydown={async () => {
            disableCrossmint = true;
            await updateTicketPrice();
            await new Promise((resolve) => setTimeout(resolve, 500));
            disableCrossmint = false;
          }}
        />

        <input
          class="w-full rounded p-3 my-1"
          type="text"
          placeholder="Promo code"
          bind:value={purchaseTicketsForm.promoCode}
          on:keydown={async () => {
            disableCrossmint = true;
            await updateTicketPrice();
            await new Promise((resolve) => setTimeout(resolve, 500));
            disableCrossmint = false;
          }}
        />

        {#if purchaseTicketsForm.errorMessage}
          <div class="text-red-500">
            {purchaseTicketsForm.errorMessage}
          </div>
        {/if}

        <div
          class="flex flex-col lg:flex-row justify-between items-center mt-2"
        >
          <div class="text-center flex-initial">
            {toBePurchasedCount ? toBePurchasedCount : '_'} tickets remaining
          </div>
          <div class="flex justify-end gap-2 items-center grow">
            {#if !disableCrossmint}
              <div
                on:click={(e) => {
                  const numTickets = parseInt(purchaseTicketsForm.numTickets);
                  if (isNaN(numTickets) || numTickets <= 0) {
                    purchaseTicketsForm.errorMessage =
                      'Quantity of tickets must be greater than 0.';
                    return false;
                  }
                }}
              >
                <crossmint-pay-button
                  collectionTitle="TKTMTX"
                  collectionDescription="The grandest ticket lottery ever!"
                  mintConfig={`{"type":"erc1155","totalPrice":"${
                    isNaN(parseInt(purchaseTicketsForm.numTickets))
                      ? ethers.utils.formatEther(latestTicketPrice.add(1000))
                      : ethers.utils.formatEther(
                          latestTicketPrice
                            .mul(parseInt(purchaseTicketsForm.numTickets))
                            .add(
                              10000 * parseInt(purchaseTicketsForm.numTickets)
                            )
                        )
                  }","numTickets":"${
                    purchaseTicketsForm.numTickets
                  }","promoCode":"${
                    purchaseTicketsForm.promoCode !== ''
                      ? purchaseTicketsForm.promoCode
                      : '0x0000000000000000000000000000000000000000000000000000000000000000'
                  }"}`}
                  clientId="3439df0d-cf03-427f-a913-6a1450859f46"
                />
              </div>
            {/if}
            <button
              type="submit"
              class="px-3 py-3 rounded text-black bg-white font-sans text-sm"
            >
              {#if purchaseTicketsForm.loading}
                <div class="flex justify-center items-center">
                  <div
                    class="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full text-primary-green"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              {:else}
                {signer === null ? 'Connect wallet' : 'Purchase'}
              {/if}
            </button>
          </div>
        </div>
        {#if purchaseTicketsForm.successMessage}
          <div class="mt-10">
            <Accordion>
              <AccordionItem key="ticket-ids-and-promo-codes">
                <h2 slot="header" class="text-xl font-bold">
                  >> Purchase successful! View ticket IDs
                </h2>
                <div slot="body" class="whitespace-pre-line">
                  {purchaseTicketsForm.successMessage}
                </div>
              </AccordionItem>
            </Accordion>
          </div>
        {/if}
      </form>
    </div>

    <div class="mt-12">
      <Accordion>
        <AccordionItem key="about-this-project">
          <h2 slot="header" class="text-2xl font-bold">>> About this project</h2>
          <p slot="body" class="ml-4 my-4">
            Ticket Matrix is a cryptocurrency lottery offering a prize of one billion dollars in MATIC. Anyone with $2 and access to the Internet can participate.
            <br /><br />
            What is truly exciting is not the prize, as only one person can win that, but proving that a protected monopoly like the lottery can be upended with a bit of code and without sacrificing security. Ticket Matrix is provably fair, driven purely by automated processes which are all publicly visible on the blockchain.
            <br /><br />
            If you are addicted to gambling, recognize that gambling is not and can never be the solution to your problems. This lottery, like any other, is just an interesting novelty. Please be responsible for yourself and those who depend upon you.
            
          </p>
        </AccordionItem>
        <AccordionItem key="how-to-play">
          <h2 slot="header" class="text-2xl font-bold">>> How to play</h2>
          <p slot="body" class="ml-4 my-4">
            1. Install the MetaMask wallet in your web browser or download the MetaMask mobile app.<br />
            2. Visit Polygon Mainnet's <a
              href="https://chainlist.org/chain/137"
              target="_blank"
              class="text-blue-500 italic">page on Chainlist</a
            > and connect your wallet, click "add to Metamask," then connect to the Polygon chain. Note: if you are using the MetaMask browser on Android, the link may open up in your default browser. To add the network to your wallet, return to the MetaMask app and paste the Chainlist URL into your address bar.<br />
            3. Return to ticketmatrix.net, specify how many tickets you wish to purchase, and click "buy with credit card." Note: failing to specify a value will lead to an unresolvable loading screen.
            <br /><br />
            Alternatively, MATIC could be exchanged directly for tickets, although this requires buying it from an exchange.
            <br /><br />
            After all one billion tickets have been purchased, one will randomly be selected as the winner, its holder being rewarded the prize.
          </p>
        </AccordionItem>
        <AccordionItem key="selling-tickets">
          <h2 slot="header" class="text-2xl font-bold">>> Selling tickets</h2>
          <p slot="body" class="ml-4 my-4">
          If you purchase 100 tickets or more, you will be given your own promo code. When customers use it, 10% of the value of the sale will be deposited into your wallet. Selling 1% of the total tickets (ten million/one billion) entitles you to 1% of the gross profit of the lottery, up to 50%, as does selling the winning ticket.
          <br /><br />
          Upon selling 100 tickets, you will be given another promo code, which could be assigned to a "subsidiary," in effect making you a "distributor." Sales by subsidiaries count toward the distributor's number of total tickets sold, and the distributor is entitled to 1% of a subsidiary's profits, including the sale of the winning ticket.
          <br /><br />
          When a promo code is issued, the contract emits a PromoCodeIssued event, which contains the promo code and the address of the distributor. In order to assign a promo code to a subsidiary, the distributor invokes the "assignSubsidiary" function. For less technically inclined ticket salesmen, the webpage UI displays the ID of a promo code after 100 tickets have been purchased. No advanced knowledge is needed to begin using this code, and one could learn later how to assign any additional codes to subsidiaries. One could also ask or pay a distributor for a subsidiary promo code.
          </p>
        </AccordionItem>
        <AccordionItem key="our-development-team">
          <h2 slot="header" class="text-2xl font-bold">
            >> Our development team
          </h2>
          <p slot="body" class="ml-4 my-4">
            This project was developed and audited by <a
              href="https://www.antematter.io/"
              target="_blank"
              class="text-blue-500 italic">Antematter</a
            >. We are a software agency specializing in blockchain, defi, and SaaS.
          </p>
        </AccordionItem>
        <AccordionItem key="social-media">
          <h2 slot="header" class="text-2xl font-bold">>> Social media</h2>
          <p slot="body" class="ml-4 my-4">
            Your Ticket Matrix social media, advertising your promo code, could be featured here. Message
            <span class="text-blue-500">cbirdano@protonmail.com</span> for inquiries.
          </p>
        </AccordionItem>
      </Accordion>
    </div>
  </div>
</main>

<style global lang="postcss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
